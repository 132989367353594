<template>
  <Dialog
    v-model:visible="modalDevolucion"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '80vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-replay" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Detalles de la devolución</strong>
        </h4>
      </div>
    </template>
    <div class="w-full text-xxs">
      <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
        <div class="grid grid-rows-4 gap-1 pt-3">
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Código Cliente/Entidad: </strong>
            </div>
            <div class="col-span-3">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.CardCode"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Nombre Cliente/Entidad: </strong>
            </div>
            <div class="col-span-3">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.CardName"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Usuario: </strong>
            </div>
            <div class="col-span-3">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.U_PHR_UserWs"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Numero de documento: </strong>
            </div>
            <div class="col-span-3">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.DocNum" disabled/>
            </div>
          </div>
        </div>
        <div class="grid grid-rows-4 gap-1 pt-1">
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de Contabilización: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.TaxDate"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de documento: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.DocDate"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de vencimiento: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.DocDueDate"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Num. Entrada Mercancia: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="devolucion.DocNumEntrada"
                         disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6 w-full">
      <div>
        <DataTable
          :value="devolucion.detalle"
          class="p-datatable-sm text-xxs"
          showGridlines
          dataKey="LineNum"
          responsiveLayout="scroll"
        >
          <template #empty>
            No hay datos para mostrar
          </template>
          <Column field="LineNum" header="Indice" headerStyle="justify-content: center;" style="min-width:5rem"
                  bodyStyle="text-align: center"/>
          <Column field="" header="Articulo" headerStyle="justify-content: center;" style="min-width:30rem"
                  bodyStyle="text-align: center">
            <template #body="{data}">
              <div class="grid grid-cols-1 w-full">
                <div class="flex justify-between items-center my-1">
                  <div>
                    <strong>Código Mx: </strong>{{ data.ItemCode }}
                  </div>
                  <div class="flex gap-2">
                    <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                           class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                    <Badge v-if="data.Controlado" value="Controlado"
                           class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                    <Badge v-if="data.Regulado" value="Regulado"
                           class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                    <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                           class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                  </div>
                </div>
                <div class="flex">
                  <strong>Nombre: </strong>{{ data.ItemName }}
                </div>
              </div>
            </template>
          </Column>
          <Column field="" header="Cantidad" headerStyle="justify-content: center;" style="min-width:8rem"
                  bodyStyle="text-align: center">
            <template #body="{data}">
              <div>{{ $h.formatNumber(data.Quantity) }}</div>
            </template>
          </Column>
          <Column field="" header="Precio" headerStyle="justify-content: center;" style="min-width:8rem"
                  bodyStyle="text-align: center">
            <template #body="{data}">
              <div>{{ $h.formatCurrency(data.PriceBefDi) }}</div>
            </template>
          </Column>
          <Column field="" header="Total" headerStyle="justify-content: center;" style="min-width:8rem"
                  bodyStyle="text-align: center">
            <template #body="{data}">
              <div>{{ $h.formatCurrency(data.LineTotal) }}</div>
            </template>
          </Column>
          <Column field="" header="Lotes" headerStyle="justify-content: center;" style="min-width:10rem"
                  bodyStyle="text-align: center">
            <template #body="{data}">
              <Button type="button" class="p-button-xs" icon="pi pi-eye" label="Ver"
                      @click="toggleLotesDev($event,data)"
                      aria-haspopup="true" aria-controls="op_lotes_dev"/>
              <OverlayPanel ref="opLotesDev" appendTo="body" :showCloseIcon="true" id="op_lotes_dev"
                            style="width: 400px"
                            :breakpoints="{'960px': '75vw'}">
                <DataTable
                  :value="lotesListDev"
                  class="p-datatable-sm text-xs"
                  showGridlines
                  dataKey="id"
                  responsiveLayout="scroll"
                >
                  <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;" style="min-width:5rem"
                          bodyStyle="text-align: center"/>
                  <Column field="ExpDate" header="Fec. Vencimiento" headerStyle="justify-content: center;"
                          style="min-width:5rem"
                          bodyStyle="text-align: center"/>
                  <Column field="CantidadLote" header="Cantidad" headerStyle="justify-content: center;"
                          style="min-width:5rem"
                          bodyStyle="text-align: center">
                    <template #body="slotProps">
                      <div>{{ $h.formatNumber(slotProps.data.CantidadLote) }}</div>
                    </template>
                  </Column>
                  <Column field="Ubicacion" header="Ubicación" headerStyle="justify-content: center;"
                          style="min-width:8rem"
                          bodyStyle="text-align: center"/>
                </DataTable>
              </OverlayPanel>
            </template>
          </Column>
          <Column field="" header="Bodega" headerStyle="justify-content: center;" style="min-width:15rem"
                  bodyStyle="text-align: center">
            <template #body="{data}">
              <div>{{ data.WhsCode }} - {{ data.nomBodega }}</div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="w-full mt-4">
      <div class="w-full text-xxs grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Comentarios: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              disabled
              :autoResize="true"
              v-model="devolucion.Comments"
              rows="2"
              cols="40"
            />
          </div>
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Entrada de diario: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              disabled
              :autoResize="true"
              v-model="devolucion.JrnlMemo"
              rows="2"
              cols="40"
            />
          </div>
          <div>
          </div>
        </div>
        <div class="lg:col-span-1">
          <div class="flex justify-between border-b-2 pb-3">
            <span class="text-gray-600">Total sin descuento:</span>
            <span class="">{{ $h.formatCurrency(devolucion.DocTotalBefore) }}</span>
          </div>
          <div class=" flex justify-between my-3">
            <div>
              <span class="text-gray-600 mr-4">% Descuento:</span>
              <InputNumber disabled inputId="integeronly" :min="0" :max="100" inputClass="p-inputtext-xxs text-xxs w-10"
                           v-model="devolucion.DiscPrcnt"/>
            </div>
            <div>
              <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
              <span class="">{{ $h.formatCurrency(devolucion.vlrDisc) }}</span>
            </div>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total IVA:</span>
            <span class="">{{ $h.formatCurrency(devolucion.TaxTotal) }}</span>
          </div>
          <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
            <span class="font-bold">Total:</span>
            <span class="">{{ $h.formatCurrency(devolucion.DocTotal) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between align-center mt-5">
      <Button
        v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-entradas.cancelar-devolucion') && showCancelButton"
        icon="pi pi-times"
        label="Cancelar Documento"
        class="p-button-rounded p-button-danger content-center text-sm"
        @click="cancelPurchaseReturn"
      />
    </div>
  </Dialog>
</template>

<script>
import { ref } from 'vue'
import RecepcionPedidosService from '../../../services/rcp-pedidos.service'
import Swal from 'sweetalert2'

export default {
  name: 'modalDetalleDevolucion',
  emits: ['refresh'],
  setup (props, { emit }) {
    const opLotesDev = ref()
    const showCancelButton = ref(false)
    const modalDevolucion = ref(false)
    const devolucion = ref({})
    const lotesListDev = ref([])
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const openModalDevolucion = (data) => {
      showCancelButton.value = data.EntradaActivaDeRecepcion
      devolucion.value = data
      modalDevolucion.value = true
    }
    const toggleLotesDev = (event, data) => {
      lotesListDev.value = data.BatchNumbers
      opLotesDev.value.toggle(event)
    }
    const cancelPurchaseReturn = async () => {
      await Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea cancelar esta devolución?',
        showConfirmButton: true,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Si, cancelar',
        cancelButtonText: 'No'
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          await _RecepcionPedidosService.cancelarDevolucion(devolucion.value.DocEntry, {
            idRecepcion: devolucion.value.idRecepcion,
            DocNumEntrada: parseInt(devolucion.value.DocNumEntrada)
          }).then(({ data }) => {
            Swal.fire({
              icon: 'success',
              title: 'Operación exitosa',
              text: 'Se cancelo la devolución correctamente',
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true
            }).then((resp) => {
              modalDevolucion.value = false
              emit('refresh')
            })
          })
        }
      })
    }
    return {
      modalDevolucion,
      devolucion,
      opLotesDev,
      lotesListDev,
      showCancelButton,
      toggleLotesDev,
      openModalDevolucion,
      cancelPurchaseReturn
    }
  }
}
</script>

<style scoped>
.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

:global(.swal2-container) {
  z-index: 1000000 !important
}
</style>
